import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  List,
  ListItem,
  ListItemText,
  Link
} from "@material-ui/core";
import React from "react";
import UserEmail from './UserEmail';

function UserList({ users, meta, pageChange }) {
  const handlePageChange = (event, page) => {
    pageChange(page);
  };

  const formatUserRole = (userRole) => {
    let label = userRole.role.name;
    if (userRole.congregation.name) {
      label += " (" + userRole.congregation.name + ")"
    }
    return label;
  }

  return (
    <Paper>
      {users.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Edit Roles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    {user.first_name} {user.last_name}
                  </TableCell>
                  <TableCell>
                    <UserEmail email={user.email} verified={user.email_verified} />
                  </TableCell>
                  <TableCell>
                    <List dense={true} disablePadding={true}>
                      {user.roles
                        .map((userRole, index) => (
                          <ListItem key={index}>
                            <ListItemText
                              primary={formatUserRole(userRole)}
                            />
                          </ListItem>
                        ))
                      }
                    </List>
                  </TableCell>
                  <TableCell>
                    <Link color="secondary" variant="button" href={"/admin/users/" + user.id + "/roles"}>
                      Edit
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  page={meta.page.page_number}
                  count={meta.page.total_elements}
                  rowsPerPage={meta.page.page_size}
                  onChangePage={handlePageChange}
                ></TablePagination>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
    </Paper>
  );
}

export default UserList;
