import queryString from "query-string";
import api from "../../api";
import httpClient from "../../http/http-client";

const REQUEST_USERS = "llc-archive/user/REQUEST_USERS";
export const requestUsers = () => ({
  type: REQUEST_USERS
});

const RECEIVE_USERS = "llc-archive/user/RECEIVE_USERS";
export const receiveUsers = (users, meta) => ({
  type: RECEIVE_USERS,
  users,
  meta
});

const REQUEST_USER = "llc-archive/user/REQUEST_USER";
export const requestUser = () => ({
  type: REQUEST_USER
});

const RECEIVE_USER = "llc-archive/user/RECEIVE_USER";
export const receiveUser = user => ({
  type: RECEIVE_USER,
  user
});

export const fetchUsers = page => dispatch => {
  const params = {
    page_number: page || 0,
    page_size: 25
  };

  dispatch(requestUsers());

  return httpClient
    .get(`${api.users}?${queryString.stringify(params)}`)
    .then(resp => resp.json())
    .then(resp => dispatch(receiveUsers(resp.users, resp.meta)));
};

export const fetchUser = userId => dispatch => {
  dispatch(requestUser());

  return httpClient
    .get(api.user(userId))
    .then(resp => resp.json())
    .then(resp => dispatch(receiveUser(resp)));
};

const initialState = {
  users: [],
  meta: {}
};

export const selectUsers = state => state.userState.users || [];

export const selectUser = (userId, state) =>
  state.userState.users.find(user => user.id === userId);

export const selectMeta = state => state.userState.meta || {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_USERS:
      return {
        ...state,
        users: action.users,
        meta: action.meta
      };

    case RECEIVE_USER:
      return {
        ...state,
        users: [...state.users, action.user]
      };

    default:
      return state;
  }
}
